import React, { FC } from 'react'
import { ButtonCore } from '../../Button/ButtonCore'

interface FileUpload {
	name: string | null
	base64: string | null
}

interface FileProps {
	key?: string
	id?: string
	supportedFileTypes?: string[]
	placeholder: string | null
	label: string | null
	body: string | null
	required: boolean
	value: FileUpload | null
	onChange: any
	error?: boolean
	errorMsg: string
}

export const InputFile: FC<FileProps> = ({
	key,
	id,
	required = false,
	placeholder,
	label,
	body,
	error,
	errorMsg,
	value,
	onChange,
}) => {
	const getBase64 = (file: any) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			onChange({
				name: file.name,
				value: reader?.result?.split(`,`)[1],
			})
		}
	}

	const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.currentTarget.files
		const file = files?.[0]

		if (file) {
			getBase64(file)
		} else {
			onChange(``)
		}
	}

	return (
		<label
			htmlFor={key || id}
			className="cursor-pointer relative font-nexa w-full"
		>
			<span className="block mb-2 text-white">{placeholder || label || body}</span>
			{errorMsg && error && required && !value && (
				<span className="mb-2 block text-primary-orange-300 font-nexa text-md absolute -top-6 w-full">
					{errorMsg}
				</span>
			)}
			<div className="relative">
				<ButtonCore className="py-2 px-6 bg-gradient-sunrise hover:bg-gradient-sky cursor-pointer hover:text-white">
					{value?.name ? value?.name : `Attach`}
				</ButtonCore>
				<input
					{...{
						id: key || id,
						name: key || id,
						type: `file`,
						required,
						onChange: _onChange,
						className: `absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer`,
					}}
					accept={`pdf`}
				/>
			</div>
		</label>
	)
}
