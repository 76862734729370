import { ChangeEvent, FC, FormEventHandler, HTMLAttributes } from 'react'
import cx from 'classnames'

interface TelProps extends HTMLAttributes<HTMLInputElement> {
	required: boolean
	label?: string
	placeholder?: string
	body?: string
	value?: string
	error?: boolean
	errorMsg?: string
	onChange: FormEventHandler<HTMLInputElement>
}

export const Tel: FC<TelProps> = ({
	required,
	placeholder,
	label,
	body,
	value,
	onChange,
	className,
	error,
	errorMsg,
	...rest
}) => {
	const _onChange = ({
		currentTarget: { value: _value },
	}: ChangeEvent<HTMLInputElement>) => {
		onChange(_value)
	}

	return (
		<div className={cx(`relative`, className)}>
			{errorMsg && error && required && !value && (
				<label className="mb-2 block text-primary-orange-300 font-nexa text-md -top-6 absolute">
					{errorMsg}
				</label>
			)}
			<input
				{...{
					type: `text`,
					required,
					placeholder: placeholder || label || body,
					value,
					onChange: _onChange,
					className: cx(
						`appearance-none outline-none focus:ring-0 p-4 rounded-md shadow-md font-nexa placeholder:text-primary-slate-300 placeholder:opacity-60 h-fit w-full bg-primary-slate-100`,
						className
					),
					...rest,
				}}
			/>
		</div>
	)
}
