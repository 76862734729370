import { convertToHexStops } from '@/utils/convertHexStops'
import { FC } from 'react'
import cx from 'classnames'
import { PipeProps } from '@/types/components'

export const PipeFive: FC<PipeProps> = ({
	pipeColour,
	invertPipeGradient,
	id,
	className = ``,
	opacityMask = false,
}) => {
	const { stops } = convertToHexStops(pipeColour)

	return (
		<div
			className={cx(
				`absolute w-full h-full top-0 left-0 overflow-hidden lg:flex`,
				className,
				{
					'mask-bottom': opacityMask,
					'mask-top': opacityMask && invertPipeGradient,
				}
			)}
		>
			<svg
				id={`uuid-d72f63c1-72dd-4312-a9f4-${id}`}
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				viewBox="0 0 1405.61 1564"
				className="w-[300vw] lg:!w-auto absolute right-0 bottom-0 lg:left-0 lg:relative lg:max-w-[1150px]"
			>
				<defs>
					<linearGradient
						id={`uuid-eef3c351-b5fe-4199-93dc-${id}`}
						x1="702.81"
						y1="1550.23"
						x2="702.81"
						y2="57.02"
						gradientUnits="userSpaceOnUse"
					>
						{stops.length > 1 ? (
							invertPipeGradient ? (
								<>
									<stop offset="0" stopColor={stops[1]} />
									<stop offset="0.85" stopColor={stops[0]} />
								</>
							) : (
								<>
									<stop offset="0" stopColor={stops[0]} />
									<stop offset="0.85" stopColor={stops[1]} />
								</>
							)
						) : (
							<stop offset="0" stopColor={stops[0]} />
						)}
					</linearGradient>
				</defs>
				<path
					d="M.03,2.7L532.79,535.47V1564h872.82V646.46c0-232.76-71.59-457.81-202.21-646.46H0L.03,2.7Z"
					style={{
						fill: `url(#uuid-eef3c351-b5fe-4199-93dc-${id})`,
						fillRule: `evenodd`,
					}}
				/>
			</svg>
		</div>
	)
}
