type GradientTypes = {
	stops: string[]
}

export const convertToHexStops = (gradient: string) => {
	const result: GradientTypes = {
		stops: [],
	}

	switch (gradient) {
		case `gradient-slate`:
			result.stops = [`#1d1d1b`, `#e2e2e4`]
			break
		case `gradient-midnight`:
			result.stops = [`#1d1d1b`, `#d12e26`]
			break
		case `gradient-slate-pink`:
			result.stops = [`#1d1d1b`, `#e94262`]
			break
		case `gradient-sky`:
			result.stops = [`#006fa8`, `#00a7a7`]
			break
		case `gradient-mid-sky`:
			result.stops = [`#19acac`, `#80d2d2`]
			break
		case `gradient-light-sky`:
			result.stops = [`#00a7a7`, `#fff`]
			break
		case `gradient-sunset`:
			result.stops = [`#e94262`, `#fbb900`]
			break
		case `gradient-sunrise`:
			result.stops = [`#f27d31`, `#fbb900`]
			break
		case `gradient-yellow`:
			result.stops = [`#fbb900`, `transparent`]
			break
		case `gradient-coral`:
			result.stops = [`#e94262`, `#e2e2e4`]
			break
		case `solid-pink`:
			result.stops = [`#e94262`]
			break
		case `solid-slate`:
			result.stops = [`#1d1d1b`]
			break
		case `solid-white`:
			result.stops = [`#fff`]
			break
		case `solid-red`:
			result.stops = [`#d12e26`]
			break
		case `solid-blue`:
			result.stops = [`#00a7a7`]
			break
		case `solid-grey`:
			result.stops = [`#e2e2e4`]
			break
		default:
			break
	}

	return result
}
