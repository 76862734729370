import { FC, ChangeEvent } from 'react'
import cx from 'classnames'

interface FreeTextProps {
	required: boolean
	placeholder: string
	label?: string
	body?: string
	value: string
	className: string
	inputClasses?: string
	error?: boolean
	errorMsg?: string
	onChange: (value: ChangeEvent<HTMLTextAreaElement>) => void
}

export const FreeText: FC<FreeTextProps> = ({
	required = false,
	placeholder,
	label = ``,
	body = ``,
	value,
	onChange,
	className,
	inputClasses = ``,
	error = false,
	errorMsg,
	...rest
}) => {
	const _onChange = ({
		currentTarget: { value: _value },
	}: ChangeEvent<HTMLTextAreaElement>) => {
		onChange(_value)
	}

	return (
		<div className={cx(``, className)}>
			{label && <label className="mb-2 block text-white font-nexa">{label}</label>}
			{errorMsg && error && required && !value && (
				<label className="mb-2 block text-primary-orange-300 font-nexa text-md absolute top-[calc(100%+0.25rem)]">
					{errorMsg}
				</label>
			)}
			<textarea
				{...{
					required,
					rows: 6,
					placeholder: placeholder || body,
					value,
					onChange: _onChange,
					className: cx(
						`outline-none appearance-none focus:ring-0 p-4 rounded-md shadow-md font-nexa placeholder:text-primary-slate-300 placeholder:opacity-60 h-fit w-full bg-primary-slate-100`,
						inputClasses
					),
					...rest,
				}}
			/>
		</div>
	)
}
