import { Layout } from '@/hoc/Layout'
import { graphql } from 'gatsby'
import {
	createElement,
	FC,
	useState,
	CSSProperties,
	useRef,
	useEffect,
} from 'react'
import BackgroundImage from 'gatsby-background-image'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { Title } from '@/components/UI/TitleCore'
import { Text } from '@/components/UI/TextCore'
import { Button } from '@/components/UI/Button'
import { BrandLine } from '@/components/UI/BrandLine'
import { ShortText } from '@/components/UI/Inputs/ShortText'
import { Numeric } from '@/components/UI/Inputs/Numeric'
import { FreeText } from '@/components/UI/Inputs/FreeText'
import { Dropdown } from '@/components/UI/Dropdown'
import { Tel } from '@/components/UI/Inputs/Tel'
import { InputFile } from '@/components/UI/Inputs/File'

import { updateObject } from '@/utils/updateObject'
import { ButtonCore } from '@/components/UI/Button/ButtonCore'
import { Anchor } from '@/components/UI/Anchor'
import Icon from '@/components/UI/Icon'
import { Container } from '@/components/UI/Container'
import { PacmanLoader } from 'react-spinners'
import { TwitterShareButton, LinkedinShareButton } from 'react-share'

import { checkValidity } from '@/utils/checkValidity'
import { useMenuState } from '@/context'
import { AnimatedTitle } from '@/components/UI/AnimatedTitle'
import { AnimatedText } from '@/components/UI/AnimatedText'

import cx from 'classnames'
import { useInView, motion } from 'framer-motion'
import { PipeFive } from '@/components/UI/BackgroundPipes/Pipes/PipeFive'

const JobsTemplate: FC = ({
	data: { job, background, character },
	location,
}) => {
	const _image = getImage(background.gatsbyImageData)
	const _background = convertToBgImage(_image)
	const _character = getImage(character.gatsbyImageData)

	const {
		id,
		attributes: {
			name_requirement,
			phone_requirement,
			cover_letter_requirement,
			resume_requirement,
		},
	} = job

	const [messaging, setMessaging] = useState<string>(``)
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)
	const [success, setSuccess] = useState<boolean>(false)
	const formRef = useRef()
	const ref = useRef(null)
	const isInView = useInView(ref, {
		margin: `100px 0px -100px 0px`,
	})

	const { setMenuOpen } = useMenuState()

	useEffect(() => {
		setMenuOpen(false)

		return () => {
			// ensure closure
			setMenuOpen(false)
		}
	}, [setMenuOpen])

	const [applicationForm, setApplicationForm] = useState({
		first_name: {
			elementType: `ShortText`,
			elementConfig: {
				type: `text`,
				placeholder: `First Name`,
				name: `first-name`,
			},
			value: ``,
			validation: {
				required: job.attributes.name_requirement !== `optional`,
				isEmail: false,
			},
			validationMessage: `Your First Name is required.`,
			valid: false,
		},
		last_name: {
			elementType: `ShortText`,
			elementConfig: {
				type: `text`,
				placeholder: `Last Name`,
				name: `last-name`,
			},
			value: ``,
			validation: {
				required: job.attributes.name_requirement !== `optional`,
				isEmail: false,
			},
			validationMessage: `Your Last name is required.`,
			valid: false,
		},
		email: {
			elementType: `ShortText`,
			elementConfig: {
				type: `email`,
				placeholder: `Email Address`,
				name: `email`,
			},
			value: ``,
			validation: {
				required: true,
				isEmail: true,
			},
			validationMessage: `Your Email is required.`,
			valid: false,
		},
		phone: {
			elementType: `Tel`,
			elementConfig: {
				type: `tel`,
				placeholder: `Phone`,
				name: `phone`,
			},
			value: ``,
			validation: {
				required: job.attributes.resume_requirement !== `optional`,
				isEmail: false,
			},
			validationMessage: `Your name is required.`,
			valid: false,
		},
		resume: {
			elementType: `InputFile`,
			elementConfig: {
				type: `file`,
				placeholder: `CV / Resume`,
				name: `resume`,
			},
			value: ``,
			validation: {
				required: true,
				isEmail: false,
			},
			validationMessage: `Your Resume is required.`,
			valid: false,
		},
		linkedIn: {
			elementType: `ShortText`,
			elementConfig: {
				type: `text`,
				placeholder: `LinkedIn URL`,
				name: `linkedin-url`,
			},
			value: ``,
			validation: {
				required: false,
				isEmail: false,
			},
			validationMessage: `Your LinkedIn URL is required.`,
			valid: false,
		},
		coverLetter: {
			elementType: `FreeText`,
			elementConfig: {
				type: `text`,
				placeholder: `Message`,
				name: `cover-letter`,
				label: `Tell us about yourself`,
			},
			value: ``,
			validation: {
				required: job.attributes.cover_letter_requirement !== `optional`,
				isEmail: false,
			},
			validationMessage: `Your Cover Letter is required.`,
			valid: false,
		},
	})

	const formElementsArray = []
	for (const key in applicationForm) {
		formElementsArray.push({
			id: key,
			config: applicationForm[key],
		})
	}

	const FormComponents = {
		Dropdown,
		Numeric,
		ShortText,
		Tel,
		FreeText,
		InputFile,
	}

	const formInputChangeHandler = (value, controlName) => {
		const updatedControls = updateObject(applicationForm, {
			[controlName]: updateObject(applicationForm[controlName], {
				value,
				valid: checkValidity(value, applicationForm[controlName].validation),
			}),
		})
		setApplicationForm(updatedControls)
	}

	const form = formElementsArray.map(({ id, config }) => {
		const { elementType, elementConfig, validation, value, validationMessage } =
			config

		if (FormComponents[elementType]) {
			const classNames = elementType === `FreeText` ? `w-full col-span-full` : ``
			return createElement(FormComponents[elementType], {
				value,
				placeholder: elementConfig.placeholder,
				onChange: (value) => formInputChangeHandler(value, id),
				required: validation.required,
				label: elementConfig.label,
				className: classNames,
				errorMsg: validationMessage,
				error,
			})
		}
	})

	const handleCheckCandidate = async (email) => {
		const checkCandidate = await fetch(`/.netlify/functions/getCandidates`, {
			method: `POST`,
			body: JSON.stringify({ email }),
		})
		const candidateResponse = await checkCandidate.json()

		return candidateResponse
	}

	const handleFileUploads = async (fileData) => {
		const fileHandler = await fetch(`/.netlify/functions/fileHandler`, {
			method: `POST`,
			body: JSON.stringify(fileData),
		})
		const data = await fileHandler.json()
		return data
	}

	const handleCreateCandidate = async (updatedData) => {
		const submitHandler = await fetch(`/.netlify/functions/createCandidate`, {
			method: `POST`,
			body: JSON.stringify(updatedData),
		})

		const resp = await submitHandler.json()

		return resp
	}

	const handleApplication = async (updatedData, candidateId) => {
		const cl = updatedData
			.map(({ value, name }) => {
				if (name === `cover-letter`) {
					return value
				} else {
					return undefined
				}
			})
			.filter((element) => element !== undefined)
			.toString()

		const assignUser = await fetch(`/.netlify/functions/assignUserToJob`, {
			method: `POST`,
			body: JSON.stringify({
				userId: candidateId,
				jobId: id,
				coverLetter: cl,
			}),
		})

		const response = await assignUser.json()

		return response
	}

	const handleSubmit = async (event) => {
		event.preventDefault()
		setError(false)
		let isError = false
		setSuccess(false)
		const formData = []
		const fileData = []
		for (const key in applicationForm) {
			if (
				applicationForm[key].validation.required &&
				!applicationForm[key].valid
			) {
				isError = true
				setError(true)
				return
			}

			formData.push({
				id: key,
				value: applicationForm[key].value,
				name: applicationForm[key].elementConfig.name,
				type: applicationForm[key].elementConfig.type,
			})

			if (applicationForm[key].elementConfig.type === `file`) {
				fileData.push({
					id: key,
					value: applicationForm[key].value,
					name: applicationForm[key].elementConfig.name,
					type: applicationForm[key].elementConfig.type,
				})
			}
		}

		if (!error || !isError) {
			setLoading(true)
			setMessaging(`Handling file assets...`)
			const uploads = await handleFileUploads(fileData)
			setMessaging(`Files handled!`)

			if (await uploads) {
				setMessaging(`Checking Candidate...`)
				const candidate = await handleCheckCandidate(applicationForm.email.value)

				if ((await candidate.data.length) > 0) {
					setMessaging(`Candidate Exists!`)

					const updatedData = formData.map((d, i) => {
						const updatedFiles = uploads.find((f) => f.id === d.id)
						return updatedFiles ? { ...d, ...updatedFiles } : d
					})

					setMessaging(`Submitting Application...`)

					const application = await handleApplication(
						updatedData,
						candidate.data[0].id
					)

					if (await application.data) {
						setMessaging(`Application Submitted!`)
						setLoading(false)
						setSuccess(true)
						setError(false)
					} else {
						setMessaging(`You have already submitted an application for this Job!`)
						setSuccess(false)
						setLoading(false)
						setError(true)
					}
				} else {
					setMessaging(`Creating candidate...`)

					const updatedData = formData.map((d, i) => {
						const updatedFiles = uploads.find((f) => f.id === d.id)
						return updatedFiles ? { ...d, ...updatedFiles } : d
					})

					const createCandidate = await handleCreateCandidate(updatedData)
					setMessaging(`Candidate Created!`)

					if (await createCandidate.data) {
						setMessaging(`Submitting Application...`)

						const application = await handleApplication(
							updatedData,
							createCandidate.data.id
						)

						if (await application.data) {
							setMessaging(`Application Submitted!`)
							setSuccess(true)
							setError(false)
						} else {
							setMessaging(`Application Failed!`)
							setLoading(false)
							setSuccess(false)
							setError(true)
						}
					} else {
						setMessaging(`Something went wrong creating a Candidate!`)
					}
				}
			}
		}
	}

	const scrollToForm = () => {
		formRef.current.scrollIntoView({ behavior: `smooth` })
	}

	return (
		<Layout locale={`en`}>
			<BackgroundImage
				{..._background}
				style={{
					backgroundPosition: `bottom left`,
					overflow: `hidden`,
				}}
			>
				<div className="max-h-[450px] h-full py-28 flex items-center justify-center flex-col text-white text-center gap-6 relative">
					<AnimatedText.Medium
						noMargin
						delay={0}
						className={`mx-auto py-2 px-4 bg-primary-slate rounded-lg`}
					>
						{job.attributes.department}
					</AnimatedText.Medium>
					<AnimatedTitle.Large
						variant="h1"
						animation="opacity"
						className="max-w-xl mx-auto"
					>
						{job.attributes.title}
					</AnimatedTitle.Large>
				</div>

				<div
					ref={ref}
					className={`absolute lg:zoom-bottom-left bottom-4 left-4 hidden md:block md:max-w-[200px] lg:max-w-[255px]`}
				>
					<GatsbyImage image={_character} alt={`something`} className="float" />
				</div>
			</BackgroundImage>

			<div
				className={cx(``, {
					'bg-primary-blue-300': job.attributes.department === `Games`,
					'bg-primary-red': job.attributes.department === `Marketing`,
				})}
			>
				<Container className={`py-6`}>
					<div className="flex flex-row gap-4 flex-wrap justify-between items-end lg:w-11/12 mx-auto">
						<Button.Core
							className="bg-gradient-sunrise"
							onClick={() => scrollToForm()}
						>
							Apply Now
						</Button.Core>
					</div>
				</Container>
			</div>
			<div className="py-16 bg-primary-slate-100 relative">
				<span className="block absolute bottom-0 left-0 w-full h-[20%] lg:h-[17.5%] bg-white z-0 lg:rounded-tr-[9rem]" />
				<Container className="grid lg:grid-cols-15 auto-rows-auto relative z-10">
					<Title.Medium
						variant="h1"
						className="col-span-full lg:col-start-2 lg:col-end-12 mb-8 row-start-1"
					>
						About this Role
					</Title.Medium>
					<BrandLine className="w-[115%] !my-0 -translate-x-16 col-span-full row-start-2" />

					<div className="grid grid-cols-15 col-span-full my-10 row-start-3 pb-14 lg:pb-20">
						<div
							className="jobs-innerhtml col-span-full lg:col-start-2 lg:col-end-10 dangerous-wysiwyg"
							dangerouslySetInnerHTML={{ __html: job.attributes.body }}
						/>
					</div>
					<div className="max-w-[375px] w-full mx-auto lg:max-w-none row-start-5 lg:row-start-3 lg:row-span-2 col-span-full lg:col-start-11 lg:col-end-15">
						<div className="lg:!sticky top-12">
							<div className="lg:absolute top-0 left-0 w-full py-8 px-4 lg:py-14">
								<Anchor.Button
									to="/careers"
									className="bg-gradient-light-sky w-full mb-7 text-center !justify-center"
									withArrow
								>
									Back to Jobs
								</Anchor.Button>

								<div className="bg-gradient-sunset p-6 rounded-lg">
									<Title.Small variant="h3" className="mb-4">
										Share this Job
									</Title.Small>
									<div className="grid grid-cols-5 xl:grid-cols-6 gap-4">
										<TwitterShareButton url={location.href} title={job.attributes.title}>
											<Icon
												name="twitter"
												className="max-w-[40px] max-h-[40px] bg-primary-slate-100 p-2 rounded-md flex items-center justify-center"
											/>
										</TwitterShareButton>
										<LinkedinShareButton url={location.href} title={job.attributes.title}>
											<Icon
												name="linkedin"
												className="max-w-[40px] max-h-[40px] bg-primary-slate-100 p-2 rounded-md flex items-center justify-center"
											/>
										</LinkedinShareButton>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className="col-span-full relative lg:col-start-2 lg:col-end-10 bg-gradient-form shadow-md row-start-4 text-white p-10 -mt-20 mb-20 rounded-lg overflow-hidden lg:py-16 xl:px-16 "
						ref={formRef}
					>
						<div className="mb-8 relative z-10">
							<Title.Medium variant="h2" className="mb-2 text-white">
								Apply for this Job
							</Title.Medium>
							{error && messaging && (
								<>
									<p className="text-primary-red">{error}</p>
									<p className="text-white">{messaging}</p>
								</>
							)}
						</div>

						<form className="grid grid-cols-1 lg:grid-cols-2 gap-6 gap-y-10 lg:gap-x-6 lg:gap-y-10 text-primary-slate relative z-10">
							{!loading && !success ? (
								<>
									{form}
									<ButtonCore
										onClick={(e) => {
											setError(false)
											handleSubmit(e)
										}}
										className="bg-gradient-sunrise col-span-full max-w-fit mx-auto"
									>
										Submit Application
									</ButtonCore>
								</>
							) : (
								<span className="block col-span-full text-center">
									{loading && !success && (
										<div className="h-[100px] flex items-center justify-center -ml-10 mb-10">
											<PacmanLoader color={`#fff`} loading={true} size={50} />
										</div>
									)}
									{messaging && <p className="text-white">{messaging}</p>}\
								</span>
							)}
						</form>

						<PipeFive className="items-start" opacityMask />
					</div>
				</Container>
			</div>
		</Layout>
	)
}

export default JobsTemplate

export const query = graphql`
	query getTeamTailorPage($id: String) {
		background: file(relativePath: { eq: "images/careers-hero-bg.jpg" }) {
			id
			childImageSharp {
				gatsbyImageData(placeholder: BLURRED)
			}
		}
		character: file(relativePath: { eq: "images/careers-hero-character.png" }) {
			id
			childImageSharp {
				gatsbyImageData(placeholder: BLURRED)
			}
		}
		job: teamTailorJob(id: { eq: $id }) {
			id
			attributes {
				apply_button_text
				body
				human_status
				internal
				pinned
				status
				title
				external_application_url
				name_requirement
				resume_requirement
				cover_letter_requirement
				phone_requirement
				created_at
				updated_at
				sharing_image_layout
				mailbox
				remote_status
				recruiter_email
				location
				department
			}
		}
	}
`
