import { FC, SyntheticEvent, useState } from 'react'
import Icon from '../Icon'
import { Text } from '../TextCore'

interface DropdownProps {
	required: boolean
	value: string
	placeholder?: string
	onChange: any
	choices: any
}

interface Option {
	id: string
	body: string
}

export const Dropdown: FC<DropdownProps> = ({ choices, value, onChange }) => {
	const [open, setOpen] = useState<boolean>(false)

	const _onChange = (e: SyntheticEvent<HTMLParagraphElement>) => {
		setOpen(!open)
		onChange(e.currentTarget.dataset.value)
	}

	return (
		<div className="flex-1 flex flex-col h-14 relative">
			<div
				{...{
					className: `flex-1 flex h-14 pl-4 pr-14 rounded border-none outline-none appearance-none focus:ring-0 flex items-center justify-center  text-center text-white relative cursor-pointer`,
				}}
				onClick={() => setOpen(!open)}
			>
				<Text.Medium
					noMargin
					className="text-xl capitalize font-semibold flex flex-row items-center justify-center gap-4 text-white"
				>
					{value || `Category`}
					<Icon
						name="chevronRight"
						className={`fill-current w-[14px] h-[14px] transition-all duration-300 ${open ? `rotate-90` : `rotate-0`
							}`}
					/>
				</Text.Medium>
				<div
					className={`absolute z-30 top-full left-0 flex-col bg-white text-primary-slate w-full text-left capitalize shadow-lg ${open ? `flex` : `hidden`
						}`}
				>
					<Text.Medium
						noMargin
						data-value={``}
						className="hover:bg-primary-slate-100 px-4 py-2 cursor-pointer"
						onClick={() => onChange(``)}
					>
						None
					</Text.Medium>
					{choices.map(({ id, body }: Option) => (
						<Text.Medium
							key={id}
							noMargin
							data-value={body}
							className="hover:bg-primary-slate-100 px-4 py-2 cursor-pointer"
							onClick={(e) => _onChange(e)}
						>
							{body}
						</Text.Medium>
					))}
				</div>
			</div>
		</div>
	)
}
