import { FC, HTMLAttributes } from 'react'
import cx from 'classnames'
import { useTransform, MotionValue } from 'framer-motion'

const useParallax = (value: MotionValue<number>, distance: number) =>
	useTransform(value, [0, 1], [-distance, distance])

export const Container: FC<HTMLAttributes<HTMLDivElement>> = ({
	children,
	className,
}) => {
	return (
		<div className={cx(`mx-auto max-w-screen-2xl w-11/12`, className)}>
			{children}
		</div>
	)
}
