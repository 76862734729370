import { FC, ChangeEvent } from 'react'

interface NumericProps {
	required: boolean
	label?: string
	value: string
	placeholder?: string
	body: string
	onChange: any
}

export const Numeric: FC<NumericProps> = ({
	required = false,
	placeholder,
	label,
	body,
	value,
	onChange,
}) => {
	const _onChange = ({
		currentTarget: { value: _value },
	}: ChangeEvent<HTMLInputElement>) => {
		onChange(_value)
	}

	return (
		<input
			type="number"
			{...{
				required,
				placeholder: placeholder || label || body,
				value,
				onChange: _onChange,
				className: `flex-1 p-4 rounded border-none outline-none appearance-none focus:ring-0 bg-primary-slate-100`,
			}}
		/>
	)
}
